<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

const { t, locale: currentLocale, locales, setLocale } = useI18n()
const [isContextMenuSnown, contextMenuToggle] = useToggle(false)
const isAuthenticated = useCookie('auth')
const { updateLocale } = useCurrentUserStore()

const contextMenuItems = computed(() => locales.value
  .filter((locale: LocaleObject) => locale.code !== currentLocale.value)
  .map((locale: string | LocaleObject): ContextMenuItem => {
    const { code, name } = locale as LocaleObject

    return { type: 'item', name: code, icon: code, title: name as string }
  }),
)

const handleLanguageSwitch = (contextMenuItem: ContextMenuItem) => {
  setLocale(contextMenuItem.name as Lowercase<GqlLocaleEnum>)

  if (isAuthenticated.value) updateLocale(contextMenuItem.name.toUpperCase() as GqlLocaleEnum)
}
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <div class="relative inline-flex items-center">
    <button
      class="relative h-6 w-8 overflow-hidden rounded-sm outline-2 cursor-pointer outline-offset-1 outline-box-border hover:outline-box-border-light focus-visible:outline-base-accent"
      :aria-label="t('shared.components.language_switcher.button')"
      @click.prevent="contextMenuToggle()"
    >
      <Icon
        :name="currentLocale"
        class="size-full"
      />
    </button>

    <ContextMenu
      v-model="isContextMenuSnown"
      v-bind="$attrs"
      :items="contextMenuItems"
      class="mt-8"
      @click="handleLanguageSwitch"
    />
  </div>
</template>
