<script setup lang="ts">
const props = defineProps<{
  items: ContextMenuEntry[]
  modelValue: boolean
}>()

const emit = defineEmits<{
  (event: 'click', item: ContextMenuItem): void
  (event: 'update:modelValue', state: boolean): void
}>()

const target = ref(null)
const closeMenu = () => emit('update:modelValue', false)

const handleClick = (item: ContextMenuItem) => {
  emit('click', item)
  closeMenu()
}

onClickOutside(target, () => setTimeout(closeMenu, 0))
</script>

<template>
  <ul
    v-if="modelValue"
    ref="target"
    class="absolute right-[-3px] top-0 z-20 rounded-xl border shadow-md font-light p-1 bg-box-bg border-box-border shadow-black/15 dark:shadow-black/25"
  >
    <li
      v-if="$slots.header"
      class="flex cursor-default flex-col items-center justify-center whitespace-nowrap px-4 py-3 text-box-text-light"
    >
      <slot name="header" />
    </li>

    <li v-if="$slots.header">
      <Divider class="my-1 w-full" />
    </li>

    <li
      v-for="(item, index) in props.items"
      :key="item.name || `slot-${index}`"
    >
      <div
        v-if="item.type === 'slot'"
        class="flex flex-col whitespace-nowrap text-box-text-light rounded-lg hover:bg-box-bg-light hover:text-box-text-light"
      >
        <slot :name="item.name || 'slot'" />
      </div>

      <Hyperlink
        v-if="item.type === 'item'"
        :to="item.to"
        class="flex w-full items-center gap-2 whitespace-nowrap px-4 py-3 cursor-pointer text-box-text-light rounded-lg hover:bg-box-bg-light hover:text-box-text-light focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-base-accent"
        @click.stop.prevent="handleClick(item)"
      >
        <Icon
          v-if="item.icon"
          :name="item.icon"
          class="size-6"
        />
        {{ item.title }}
      </Hyperlink>
    </li>
  </ul>
</template>
